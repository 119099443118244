<template>
  <section id="detail-kegiatan">
    <b-row
      v-if="app_view"
    >
      <b-col
        lg="3"
        cols="12"
      >
        <b-card class="shadow-none">
          <!-- about -->
          <h2 class="text-center mb-2">
            {{ dataProgram.nama_kegiatan }}
          </h2>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="primary"
              rounded
            >
              <feather-icon
                icon="ClipboardIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>No. Tiket</small>
              <h4 class="mb-0">
                {{ dataProgram.uuid }}
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="primary"
              rounded
            >
              <feather-icon
                icon="UserIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Penanggung Jawab</small>
              <h4 class="mb-0">
                {{ dataProgram.pic }} ({{ dataProgram.pic_number }})
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="primary"
              rounded
            >
              <feather-icon
                icon="CalendarIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Tahun Anggaran</small>
              <h4 class="mb-0">
                {{ dataProgram.tahun_anggaran }}
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="primary"
              rounded
            >
              <feather-icon
                icon="ShoppingBagIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Total Anggaran</small>
              <h4 class="mb-0">
                {{ Number(dataProgram.total_anggaran).toLocaleString() }}
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="primary"
              rounded
            >
              <feather-icon
                icon="BookOpenIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Kode Mata Anggaran</small>
              <h4 class="mb-0">
                {{ dataProgram.kode_anggaran }}
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="primary"
              rounded
            >
              <feather-icon
                icon="ShoppingCartIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Jenis Belanja</small>
              <h4 class="mb-0">
                {{ dataProgram.jenis_belanja }}
                <feather-icon
                  v-if="dataProgram.prioritas_nasional === 1"
                  icon="StarIcon"
                  size="18"
                />
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="primary"
              rounded
            >
              <feather-icon
                icon="ListIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Jenis Pengadaan</small>
              <h4 class="mb-0">
                {{ dataProgram.jenis_pengadaan }}
              </h4>
            </div>
          </div>
          <div class="d-flex align-items-start my-1">
            <b-avatar
              variant="primary"
              rounded
            >
              <feather-icon
                icon="BriefcaseIcon"
                size="24"
              />
            </b-avatar>
            <div class="pl-1">
              <small>Sumber Dana</small>
              <h4 class="mb-0">
                {{ dataProgram.sumber_dana }}
              </h4>
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col
        lg="9"
        cols="12"
      >
        <b-card
          header="Infrastruktur"
          header-tag="h4"
          header-class="border-bottom mb-2"
        >

          <b-row>
            <b-col
              md="4"
              class="mb-50"
            >
              <h5>Domain Infrastruktur</h5>
              <p>{{ dataInfra.domain_infrastruktur }}</p>
            </b-col>
            <b-col
              md="4"
              class="mb-50"
            >
              <h5>Area Infrastruktur</h5>
              <p>{{ dataInfra.area_infrastruktur }}</p>
            </b-col>
            <b-col
              md="4"
              class="mb-50"
            >
              <h5>Kategori Infrastruktur</h5>
              <p>{{ (dataInfra.kategori_infra !== '') ? dataInfra.kategori_infra : '-' }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>{{ `Nama ${nama_infra}` }}</h5>
              <p>{{ dataInfra.nama }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>STATUS KEGIATAN</h5>
              <p>{{ dataInfra.status_kegiatan }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>TOTAL ANGGARAN</h5>
              <p>{{ dataInfra.total_anggaran ? Number(dataInfra.total_anggaran).toLocaleString() : 0 }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>SUMBER DANA</h5>
              <p>{{ dataInfra.sumber_dana }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>JENIS BELANJA</h5>
              <p>{{ dataInfra.jenis_belanja }}</p>
            </b-col>
            <b-col
              md="6"
              class="mb-50"
            >
              <h5>JENIS PENGADAAN</h5>
              <p>{{ dataInfra.jenis_pengadaan }}</p>
            </b-col>
          </b-row>

          <template v-if="dataInfra.domain_infra_id === 3 && dataInfra.area_infra_id === 1">
            <!-- Type Sistem Integrasi : Jaringan Intra Pemerintah -->
            <infra-sis-jar
              ref="sisJar"
              :inf-data="dataInfra"
            />
          </template>

          <template v-else-if="dataInfra.domain_infra_id === 3 && dataInfra.area_infra_id === 7">
            <!-- Type Sistem Integrasi : Sistem Penghubung Layanan Pemerintah -->
            <infra-sis-hub
              ref="sisJar"
              :inf-data="dataInfra"
            />
          </template>

          <template v-else-if="dataInfra.domain_infra_id === 2 && dataInfra.area_infra_id === 3">
            <!-- Type Platform : Komputasi Awan -->
            <infra-plat-cloud
              ref="platCloud"
              :inf-data="dataInfra"
            />
          </template>

          <template
            v-else-if="dataInfra.domain_infra_id === 2 && dataInfra.area_infra_id === 2 && dataInfra.kategori_infra_id === 8"
          >
            <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Software -->
            <infra-plat-kia-soft
              ref="platKiaSoft"
              :inf-data="dataInfra"
            />
          </template>

          <template
            v-else-if="dataInfra.domain_infra_id === 2 && dataInfra.area_infra_id === 2 && dataInfra.kategori_infra_id === 7"
          >
            <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Perangkat Keras Server -->
            <infra-plat-kia-server
              ref="platKiaServer"
              :inf-data="dataInfra"
            />
          </template>

          <template
            v-else-if="dataInfra.domain_infra_id === 2 && dataInfra.area_infra_id === 2 && dataInfra.kategori_infra_id === 5"
          >
            <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Perangkat Keras Media Penyimpanan -->
            <infra-plat-kia-storage
              ref="platKiaStorage"
              :inf-data="dataInfra"
            />
          </template>

          <template
            v-else-if="dataInfra.domain_infra_id === 2 && dataInfra.area_infra_id === 2 && dataInfra.kategori_infra_id === 3"
          >
            <infra-plat-kia-network
              ref="platKiaNetwork"
              :inf-data="dataInfra"
            />
          </template>

          <template
            v-else-if="dataInfra.domain_infra_id === 2 && dataInfra.area_infra_id === 2 && dataInfra.kategori_infra_id === 4"
          >
            <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Perangkat Keras Keamanan -->
            <infra-plat-kia-security
              ref="platKiaSecurity"
              :inf-data="dataInfra"
            />
          </template>

          <template
            v-else-if="dataInfra.domain_infra_id === 2 && dataInfra.area_infra_id === 2 && dataInfra.kategori_infra_id === 6"
          >
            <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Perangkat Keras Periferal -->
            <infra-plat-kia-periferal
              ref="platKiaPeriferal"
              :inf-data="dataInfra"
            />
          </template>

          <!-- Type Fasilitas Komputasi : ALL -->
          <template v-else>
            <infra-fasil
              ref="fasil"
              :inf-data="dataInfra"
            />
          </template>

          <b-row>
            <b-col
              v-if="dataInfra.kak_uri !== null"
              md="6"
              class="mb-50"
            >
              <h5>Kerangka Acuan Kerja</h5>
              <p>
                <b-button
                  variant="success"
                  size="sm"
                  class="mr-25 mb-25"
                  @click="viewFile(dataInfra.kak_uri)"
                >
                  <feather-icon
                    icon="BookOpenIcon"
                    size="14"
                  /></b-button>
                <b-link
                  :href="getDoc(dataInfra.kak_uri)"
                  class="btn btn-sm btn-info mr-25 mb-25"
                  target="_blank"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="14"
                  /></b-link>
                <small>{{ dataInfra.kak_uri.substring(dataInfra.kak_uri.lastIndexOf('/') + 1) }}</small>
              </p>
            </b-col>
            <b-col
              v-if="dataInfra.additional_docs.length !== 0"
              md="6"
              class="mb-50"
            >
              <h5>Dokumen Tambahan</h5>
              <p
                v-for="(data, index) in dataInfra.additional_docs"
                :key="index"
              >
                <b-button
                  variant="success"
                  size="sm"
                  class="mr-25 mb-25"
                  @click="viewFile(data.document_uri)"
                >
                  <feather-icon
                    icon="BookOpenIcon"
                    size="14"
                  /></b-button>
                <b-link
                  :href="getDoc(data.document_uri)"
                  class="btn btn-sm btn-info mr-25 mb-25"
                  target="_blank"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="14"
                  /></b-link>
                <small>{{ data.document_uri.substring(data.document_uri.lastIndexOf('/') + 1) }}</small>
              </p>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          header="Rekapitulasi Rekomendasi Sementara"
          header-tag="h4"
          header-class="border-bottom mb-2"
        >
          <summary-rekomendasi
            :rekap-lanjut="listRekomendasi.continue"
            :rekap-tidak="listRekomendasi.postpone"
          />
        </b-card>

        <b-card
          v-if="view_type === 'ask'"
          header="Apakah anda setuju dengan rekomendasi di atas?"
          header-tag="h4"
          header-class="border-bottom mb-2"
        >
          <modul-tanya-rekomendasi
            :detail-data="dataInfra"
            component-type="infrastruktur"
          />
        </b-card>

        <template
          v-else-if="view_type === 'konfirmasi'"
        >
          <b-card
            header="Tanggapan K/L"
            header-tag="h4"
            header-class="border-bottom mb-2"
          >
            <summary-tanggapan
              :component-id="Number(app_id)"
              :detail-program="dataInfra"
              step-rekomendasi="infrastruktur"
              @view-file="viewFile"
            />
          </b-card>

          <b-card
            header="Hasil Pembahasan Peninjauan Kembali"
            header-tag="h4"
            header-class="border-bottom mb-2"
          >
            <modul-rekomendasi-konfirmasi
              :component-id="Number(app_id)"
              :detail-program="dataInfra"
              step-rekomendasi="infrastruktur"
            />
          </b-card>
        </template>
      </b-col>
    </b-row>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-app
          :pdf="fileDoc"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import {
  BCardText, BRow, BCol, BCard, BLink, BButton, BModal, BAvatar,
} from 'bootstrap-vue'
import VuePdfApp from 'vue-pdf-app'

import InfraFasil from '../kegiatan/template-detail-infra/InfraFasil.vue'
import InfraSisJar from '../kegiatan/template-detail-infra/InfraSisJar.vue'
import InfraSisHub from '../kegiatan/template-detail-infra/InfraSisHub.vue'
import InfraPlatCloud from '../kegiatan/template-detail-infra/InfraPlatCloud.vue'
import InfraPlatKiaSoft from '../kegiatan/template-detail-infra/InfraPlatKiaSoft.vue'
import InfraPlatKiaServer from '../kegiatan/template-detail-infra/InfraPlatKiaServer.vue'
import InfraPlatKiaStorage from '../kegiatan/template-detail-infra/InfraPlatKiaStorage.vue'
import InfraPlatKiaNetwork from '../kegiatan/template-detail-infra/InfraPlatKiaNetwork.vue'
import InfraPlatKiaSecurity from '../kegiatan/template-detail-infra/InfraPlatKiaSecurity.vue'
import InfraPlatKiaPeriferal from '../kegiatan/template-detail-infra/InfraPlatKiaPeriferal.vue'

import SummaryRekomendasi from './SummaryRekomendasi.vue'
import SummaryTanggapan from './SummaryTanggapan.vue'
import ModulRekomendasiKonfirmasi from './ModulRekomendasiKonfirmasi.vue'
import ModulTanyaRekomendasi from './ModulTanyaRekomendasi.vue'

import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BButton,
    BAvatar,
    BModal,
    BCardText,

    InfraFasil,
    InfraSisJar,
    InfraSisHub,
    InfraPlatCloud,
    InfraPlatKiaSoft,
    InfraPlatKiaServer,
    InfraPlatKiaStorage,
    InfraPlatKiaNetwork,
    InfraPlatKiaSecurity,
    InfraPlatKiaPeriferal,

    SummaryRekomendasi,
    SummaryTanggapan,
    ModulTanyaRekomendasi,
    ModulRekomendasiKonfirmasi,
    VuePdfApp,
  },
  data() {
    return {
      data_id: '',
      app_id: '',
      app_view: false,
      view_type: '',
      fileDoc: '',
      nama_infra: '',
      rangkuman: '',
      dataProgram: {},
      dataInfra: {},
      listRekomendasi: {},
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!to.params.id) {
      next('/')
    } else {
      next()
    }
  },
  mounted() {
    this.data_id = this.$route.params.utama
    this.app_id = this.$route.params.id
    this.view_type = this.$route.params.type

    this.$http.get('/clearance/infrastruktur', {
      params: {
        token: localStorage.getItem('userToken'),
        data_utama_id: this.data_id,
        infrastruktur_id: this.app_id,
      },
    })
      .then(res => {
        if (res.data.status === 'success') {
          res.data.data.map(value => {
            this.dataInfra = value
            return true
          })
          this.getProgram()
          this.openRekomendasi()
          this.nama_infra = this.dataInfra.domain_infrastruktur
        } else {
          this.$router.replace('error-404')
        }
      })
      .catch(error => {
        console.log(error)
        this.$router.replace('error-404')
      })
  },
  methods: {
    viewFile(data) {
      this.fileDoc = `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
      this.$refs['modal-komponen'].show()
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
    getProgram() {
      this.$http.get('/clearance/core-data', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.dataProgram = res.data.data
            this.app_view = true
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    openRekomendasi() {
      const params = {
        token: localStorage.getItem('userToken'),
        data_utama_id: this.data_id,
        infrastruktur_id: this.app_id,
        type: 'infra',
      }
      this.$http.get('/recommendation/get-rekomendasi-sementara', {
        params,
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.listRekomendasi = res.data.data
          }
        })
    },
  },
}
</script>
